import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'wouter';
import { postLogin, resendVerificationCode } from '@medifind/interface';
import { useRouter } from '@medifind/router';
import { Button } from '@medifind/shared-basic-components';
import formsStyles from '@medifind/styles/components/form.module.scss';
import { Yup } from '@medifind/utils';
import { eventDispatch } from '@medifind/utils';
import { pathShouldRedirect } from '@medifind/utils';
import { checkAuthTokenRefresh, logEventGA4, login, setAuthRedirect, useAuthRedirect, useAuthentication } from '@medifind/zustand';
import { InputGroup } from './components';
import { SocialLogin } from './SocialLogin';
import authStyles from './AuthForm.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().label('Email').email('Valid email required').nullable().required('Required'),
  password: Yup.string().label('Password').min(8).nullable().required('Required'),
});

const LoginForm = (props) => {
  const { signup, showForgetPassword, showLoginTitle } = props;
  const redirecting = useRef(false);
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [isResendVerificationFlag, setIsResendVerificationFlag] = useState(false);
  const [defaultSocialLogin, setDefaultSocialLogin] = useState('');
  const authRedirect = useAuthRedirect();
  const { profile } = useAuthentication();

  useEffect(() => {
    if (profile && !redirecting.current) {
      if (authRedirect?.action === 'page') {
        router.history.push(authRedirect.data);
        setAuthRedirect();
        redirecting.current = true;
      } else if (pathShouldRedirect(router.location.pathname)) {
        router.history.push('/profile/dashboard');
        redirecting.current = true;
      }
    }
    // If authRedirect is a dep it will call this again before the history push and goto the dashboard instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    return () => {
      redirecting.current = false;
    };
  }, []);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  const onResendVerificationCode = () => {
    resendVerificationCode(email)
      .then((r) => {
        setIsResendVerificationFlag(true);
      })
      .catch(({ message }) => {
        setError('general', { message });
      });
  };

  const onSubmit = (values) => {
    setEmail(values.email);
    setDefaultSocialLogin('');
    return postLogin(values)
      .then(({ token, redirectSocialLogin }) => {
        if (token != null) {
          logEventGA4('login', { type: 'MediFind', method: 'MediFind' });
          login(token).then(() => {
            checkAuthTokenRefresh();
            eventDispatch('closeAuth');
          });
        } else if (['Google', 'Facebook'].includes(redirectSocialLogin)) {
          setDefaultSocialLogin(redirectSocialLogin);
        }
      })
      .catch((err) => {
        logEventGA4('exception', { type: 'login', description: 'Login Form Submit Error', fatal: false });
        setError('general', { message: err.message, errorCode: err?.errorCode });
      });
  };

  return (
    <>
      <div className={authStyles['social-login']}>
        <SocialLogin title={showLoginTitle && 'Log In'} {...{ email, defaultSocialLogin }} type={'sign-in'} />
      </div>
      <FormProvider {...methods}>
        <form className={classNames(formsStyles['form'], authStyles['auth-form'])} onSubmit={handleSubmit(onSubmit)}>
          {signup && !isSubmitting && !errors?.general?.message && !isResendVerificationFlag && (
            <Alert variant="success">Please check your email for a verification link before logging in.</Alert>
          )}
          {!isResendVerificationFlag && errors?.general?.errorCode === 'verification_pending' && (
            <Alert variant="danger" className={formsStyles['alert-danger']}>
              {errors?.general?.message}. Please check your email for the verification link. Or{' '}
              <Alert.Link onClick={() => onResendVerificationCode()}>Click here</Alert.Link> to get a new verification link.
            </Alert>
          )}

          {errors?.general && errors?.general?.errorCode !== 'verification_pending' && (
            <Alert variant="danger" className={formsStyles['alert-danger']}>
              {errors?.general?.message}
            </Alert>
          )}
          {isResendVerificationFlag && (
            <Alert variant="success">Verification link sent to your email. Please verify your account and try to login.</Alert>
          )}
          <InputGroup name="email" type="email" label="Email" placeholder="Email" autoComplete="username email" />
          <InputGroup name="password" label="Password" placeholder="Password" type="password" autoComplete="current-password" />
          <Button
            color="blue"
            type="thin"
            btnType="submit"
            disabled={isSubmitting && !errors}
            label={!isSubmitting ? 'Log In' : 'Logging In...'}
          ></Button>
          {showForgetPassword && (
            <span className={formsStyles['extra-links']}>
              <Link to="/recover" onClick={() => eventDispatch('closeAuth')}>
                Forgot Password?
              </Link>
            </span>
          )}
        </form>
      </FormProvider>
    </>
  );
};

LoginForm.propTypes = {
  signup: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  showLoginTitle: PropTypes.bool,
};

export { LoginForm as default, LoginForm };
